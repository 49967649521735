<template>
  <div>
    <EditorFrame
      v-if="hb"
      :hb="hb"     
      :showTags="false"
      :showPreview="'cnIdiomComb'!==hb.contentType&&'cnFlying'!==hb.contentType"
    >
      <HbPreview :hb="hb" />
      <template v-slot:buttons>
        <Button
          class="center-block-btn"
          type="primary"
          @click="postFinalCheck(0)"
          :disabled="isDisable"
        >确认无误，通过审核</Button>
        <DeleteHbButton :hb="hb" class="center-block-btn" :disabled="isDisable" />
      </template>

      <template v-slot:right-side>
        <Tabs :value="'cnIdiomComb'!==hb.contentType&&'cnFlying'!==hb.contentType?'name2':'name3'">
          <TabPane
            label="编辑状态"
            name="name2"
            v-if="'cnIdiomComb'!==hb.contentType&&'cnFlying'!==hb.contentType"
          >
            <EditStatus :hb="hb" />
          </TabPane>

          <TabPane label="版本记录" name="name1">
            <colophon :taskInfo="taskInfo"></colophon>
          </TabPane>
        </Tabs>
      </template>
    </EditorFrame>
  </div>
</template>

<script >
import axios from '../api/newAxiosProxy';
import { getHbTaskView } from '../api/hbService';
import editorMixin from '../views/editorMixin';

export default {
  data() {
    return {
      hb: null,
      taskInfo: {},
      isDisable: false, // 开关
    };
  },
  methods: {
    //获取任务详情
    initTaskView() {
      let { taskId, processId, bizId, contentType } = this.$route.query;
      let params = { taskId, processId, bizId, contentType };
      this.$Spin.show();
      getHbTaskView(params, () => {
        this.$Spin.hide();
      }).then((res) => {
        this.hb = res.content;
        this.hb.taskName = res.taskName;
        this.taskInfo = res.taskInfo;
      });
    },
    //6审最终审发布
    async postFinalCheck(e) {
      let hb = this.hb;
      hb.pass = e;
      this.isDisable = true;

      let msg = this.$Message.loading({
        content: '提交中....',
        duration: 0
      });
      let url = '/longSentence/finalCheck';      

      axios(
        {
          method: 'post',
          url,
          data: hb
        },
        () => {
          msg();
          this.isDisable = false;
        }
      )
        .then(() => {
          this.$Message.success('提交成功');
          this.isDropTask = false;
          setTimeout(() => {
            this.$router.back();
          }, 500);
        })
        .finally(() => {
          msg();
          this.isDisable = false;
        });
    }
  },
  mounted() {
    this.currentIndex = 0;
    this.initTaskView();
  },
  mixins: [editorMixin]
};
</script>

<style scoped lang="scss">
::v-deep .ivu-tabs {
  width: 600px;
}
.up_tip {
  margin-bottom: 20px;
}

.typeSelect {
  width: 200px;
  margin: 20px;
}
</style>
